@font-face {
  font-family: 'Alegreya SC';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/Alegreya-SC.woff2') format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url('../fonts/OpenSans-normal.woff2') format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

.container {
  margin-top: 50px;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
}
.logo {
  font-family: "Alegreya SC", Georgia;
  font-size: 3em;
  text-align: center;
  margin-bottom: 30px;
}
a, a:hover, a:visited {
  color: #446E9B;
  text-decoration: none;
}
.panel-title, .panel-body {
  text-align: center;
}
